import React from 'react'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'

// WL Design System
import {
  ListLayout,
  TableWrapper,
  PageHeader,
  Badge,
  PageDetailsTitle,
  SmallText,
  InternalLink,
  Alert,
  InputCode,
} from 'wildlife-design-system'

import { PRODUCT_TITLE } from '../../../constants'
import LoadingPage from '../../LoadingPage'
import LocalMenu from '../LocalMenu'
import {
  ListRow,
  ListMain,
  ListBoxContainer,
  ListBoxContainerParagraph,
  FlexibleColumnPair,
} from '../../styles'
import { Query } from '../../../objects/queries'
import { displayDate, nameAndEmailFromEmailOrName } from '../../../util'
import { queryPath } from '../../../paths'

const QueryLinkWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
`

const StyledInputCode = styled(InputCode)`
  margin-top: 15px;
`

const presentRecordFilters = record => {
  const stringify = o => JSON.stringify(o, undefined, 2)
  const fb = record.getFilterBefore()
  const sb = stringify(fb.getData())
  const fa = record.getFilterProposal()
  const sa = stringify(fa.getData())
  const diff = fb.getDifference(fa)
  const prefix = `\n// before:\n${sb}\n\n// proposed changes:\n${diff}`
  return `${prefix}${record.wasAccepted() ? `\n// after:\n${sa}\n` : ''}`
}

const StyledAlert = styled.div`
  margin-top: 15px;

  & > div {
    grid-template-columns: 5% auto 5%;
  }
`

const RequestOperation = styled.b`
  padding: 0 3px;
  font-style: bold;
`

const Motivation = styled.div`
  padding: 5px 0;
  font-style: italic;
`

const Reason = styled.div`
  padding-top: 5px;
  font-style: italic;
`

const RequestAlert = ({ record }) => {
  const author = nameAndEmailFromEmailOrName(record.getAuthor())
  const reviewer = nameAndEmailFromEmailOrName(record.getReviewer())
  // eslint-disable-next-line no-nested-ternary
  const op = record.isCreation()
    ? 'the creation'
    : (record.isDeletion()
      ? 'the deletion'
      : 'an update on the filters')

  return (
    <StyledAlert>
      <Alert type={record.wasAccepted() ? 'success' : 'error'}>
        {`${author} requested`}
        <RequestOperation>{op}</RequestOperation>
        {`of this query on ${displayDate(record.getRequestedAt())}, with the following motivation:`}
        <Motivation>{`"${record.getMotivation()}"`}</Motivation>
        {`${reviewer} reviewed this request on ${displayDate(record.getReviewedAt())}, and`}
        <RequestOperation>
          {record.wasAccepted() ? 'approved' : 'rejected'}
        </RequestOperation>
        {(record.wasRejected() && (
          <>
            it with the following reason:
            <Reason>{`"${record.getRejectReason()}"`}</Reason>
          </>
        )) || 'it.'}
      </Alert>
    </StyledAlert>
  )
}

const ListQueryRequestRecordsPage = ({
  game, gamesMenu, layoutConfig, logout, queryRequestRecords, user, history,
  queryRequestRecord, queries,
}) => {
  const { pathname, search: searchState } = useLocation()
  const search = () => new URLSearchParams(searchState)
  const queryRequestRecordID = () => search().get('query_request_record_id') || ''
  const loading = queryRequestRecordID() !== '' && queryRequestRecords.length === 0

  const boxRef = React.useRef(null)

  const handleClickRow = q => () => {
    const s = search()
    s.set('query_request_record_id', q.getID())
    history.push({
      pathname,
      search: s.toString(),
    })
    setTimeout(
      boxRef
      && boxRef.current
      && boxRef.current.scrollIntoView({ behavior: 'smooth' }),
      250,
    )
  }

  if (queryRequestRecordID() === '' && queryRequestRecords.length > 0) {
    handleClickRow(queryRequestRecords[0])()
  }

  const queryLookup = queries.reduce((acc, q) => ({ ...acc, [q.getID()]: q }), {})
  const lookupQueryName = q => (
    queryLookup[q.getQueryID()]
    || new Query({ name: q.getQueryID() })
  ).getName()
  const query = queryLookup[queryRequestRecord.getQueryID()]
    || new Query({ name: queryRequestRecord.getQueryID() })

  return loading ? <LoadingPage /> : (
    <ListLayout
      productTitle={PRODUCT_TITLE}
      config={layoutConfig}
      games={gamesMenu}
      gameSlug={game.getIcon()}
      logout={logout}
    >
      <LocalMenu
        game={game}
        user={user}
        history={history}
      />
      <PageHeader
        title={`${game.getName()} Query Request Records`}
      />
      <ListMain withBox={!!queryRequestRecord.getID()}>
        <TableWrapper>
          <table>
            <thead>
              <tr>
                <th>Query</th>
                <th>Request</th>
                <th>Status</th>
                <th>Reviewed at</th>
              </tr>
            </thead>
            <tbody>
              {queryRequestRecords.map(q => (
                <ListRow
                  onClick={handleClickRow(q)}
                  selected={q.getID() === queryRequestRecord.getID()}
                >
                  <td>{lookupQueryName(q)}</td>
                  <td>
                    {(q.isCreation() && 'Creation') || (q.isDeletion() && 'Deletion') || 'Filter Update'}
                  </td>
                  <td>{(q.wasAccepted() && <Badge profile="success">Approved</Badge>) || <Badge profile="alert">Rejected</Badge>}</td>
                  <td>{displayDate(q.getReviewedAt())}</td>
                </ListRow>
              ))}
            </tbody>
          </table>
        </TableWrapper>
        <div />
        {queryRequestRecord.getID() && (
          <div ref={boxRef}>
            <ListBoxContainer profile="sidebar">
              <FlexibleColumnPair>
                <div>
                  <PageDetailsTitle>{query.getName()}</PageDetailsTitle>
                </div>
                <QueryLinkWrapper>
                  {(query.getID() && (
                    <InternalLink
                      to={queryPath(game, query)}
                      type="content"
                    >
                      Go to query
                    </InternalLink>
                  )) || '(deleted)'}
                </QueryLinkWrapper>
              </FlexibleColumnPair>
              {query.getID() && (
                <SmallText>{`ID: ${query.getID()}`}</SmallText>
              )}
              {query.getDescription() && (
                <ListBoxContainerParagraph>
                  {query.getDescription()}
                </ListBoxContainerParagraph>
              )}
              <RequestAlert record={queryRequestRecord} />
              <StyledInputCode
                readOnly
                mode="json"
                width="100%"
                value={presentRecordFilters(queryRequestRecord)}
              />
            </ListBoxContainer>
          </div>
        )}
      </ListMain>
    </ListLayout>
  )
}

export default ListQueryRequestRecordsPage
