/* eslint-disable implicit-arrow-linebreak */
import React from 'react'
import Select from 'react-select'
import styled from 'styled-components'

import {
  Button,
  Label,
  Field,
  FieldOption,
  Radio,
  KeyValueList,
  KeyValueItem,
  IconButton,
  Input,
  InternalLink,
} from 'wildlife-design-system'

import { SmallParagraph } from '../../styles'

const allowedValueTypeOptions = [
  { value: 'string', label: 'String', fieldPathPlaceholder: 'Ex: source' },
  { value: 'empty', label: 'Empty String', fieldPathPlaceholder: 'Ex: source' },
  { value: 'number', label: 'Number', fieldPathPlaceholder: 'Ex: currentLeague' },
  { value: 'boolean', label: 'Boolean', fieldPathPlaceholder: 'Ex: matchResult.flawlessVictory' },
]

const booleanAllowedValueOptions = [
  { value: true, label: 'True' },
  { value: false, label: 'False' },
]

const FieldPairSmallText = styled(Field.Group)`
  margin-top: 1.2rem;

  & > div {
    width: 50%;
  }

  & label, time {
    font-size: 14px;
  }
`

const TruncatingKeyValueList = styled(KeyValueList)`
  & > div > dt {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
  }
`

const FieldTripleSmallInputText = styled(Field.Group)`
  & > div {
    width: 33%;
  }

  & input {
    font-size: 13px;
  }
`

const displayDataFilter = dataFilter => {
  const path = `"${dataFilter.getFieldPath()}"`
  const values = dataFilter.getAllowedValues()
    .map(v => JSON.stringify(v)).join(', ')
  return `${path}: ${values}`
}

const fieldPathPlaceholder = allowedValueType =>
  (allowedValueTypeOptions
    .filter(o => o.value === allowedValueType)[0] || allowedValueTypeOptions[0])
    .fieldPathPlaceholder

const FilterEditor = ({
  setOrigin,
  getOrigin,
  eventNames,
  removeEventName,
  eventName,
  setEventName,
  handleAddEventName,
  selectedDataFilter,
  setSelectedFieldPath,
  dataFilters,
  removeDataFilter,
  removeDataFilterByPath,
  fieldPath,
  allowedValueType,
  setFieldPath,
  setAllowedValueType,
  stringAllowedValue,
  setStringAllowedValue,
  numberAllowedValue,
  setNumberAllowedValue,
  booleanAllowedValue,
  setBooleanAllowedValue,
  handleAddDataFilter,
}) => (
  <>
    <Label htmlFor="server-logs" required>Filter by Origin</Label>
    <>
      <FieldOption
        id="server-logs"
        label="Log Origin: Game Servers"
        description="Recommended. Server logs are the most trustworthy source of events for player auditing."
      >
        <Radio
          id="server-logs"
          name="origin"
          value="server"
          onChange={e => setOrigin(e.target.value)}
          defaultChecked={getOrigin() === 'server'}
        />
      </FieldOption>
      <FieldOption
        id="client-logs"
        label="Log Origin: Game Clients"
        description="Not recommended. Logs sent directly from the game clients cannot be trusted as proofs for metagame transactions, because hackers might be able to forge them. However, they might be useful for verifying simpler interactions. For example, verifying if a player watched a specific feedback within the game, like the rewards of a purchase, or their final position in a championship."
      >
        <Radio
          id="client-logs"
          name="origin"
          value="client"
          onChange={e => setOrigin(e.target.value)}
          defaultChecked={getOrigin() === 'client'}
        />
      </FieldOption>
    </>

    <Label htmlFor="event-name">Filter by Event Name</Label>
    <>
      <SmallParagraph>
        Every log contains a field called &quot;event name&quot;, which is a human-readable
        identifier explaining what the log is all about. Ex: &quot;HardCurrencyVar&quot;
        refers to hard currency variation events.
        <br />
        <br />
        A query is allowed to filter by a list of event names, meaning that any log whose
        event name is not in this list will not match this query. The comparison is
        case-sensitive.
      </SmallParagraph>
      {eventNames.length > 0 && (
        <KeyValueList>
          {eventNames.map(n => (
            <KeyValueItem term={`"${n}"`}>
              <IconButton
                type="delete"
                iconSize="14"
                onClick={e => { e.preventDefault(); removeEventName(n) }}
              />
            </KeyValueItem>
          ))}
        </KeyValueList>
      )}
      <FieldPairSmallText>
        <Field>
          <Input
            id="event-name"
            value={eventName}
            placeholder="Ex: HardCurrencyVar"
            onChange={e => setEventName(e.target.value)}
          />
        </Field>
        <Field>
          <Button
            type="outlined"
            onClick={handleAddEventName}
          >
            Add to list
          </Button>
        </Field>
      </FieldPairSmallText>
    </>

    <Label htmlFor="field-path">Filter by Metadata</Label>
    <>
      <SmallParagraph>
        Every log also contains a field called &quot;data&quot;, which
        holds detailed information about the player event in JSON format.
        Therefore, a query is also allowed to filter by a list of data
        filters. A data filter is defined by a target path and a list of
        allowed values.
        <br />
        <br />
        The path is a sequence of keys separated by dot, where each
        key looks one level deeper in the data field of the log.
        Ex: &quot;deltaGems&quot; (only the root level),
        &quot;cardInfo.amountToNextLevel&quot; (two levels)
        <br />
        <br />
        The values can be strings, numbers or booleans.
        <br />
        <br />
        Both path and value comparisons are case-sensitive.
      </SmallParagraph>
      {selectedDataFilter && (
        <>
          <Field.Group>
            <Field>
              <Label>
                {`Allowed values for path "${selectedDataFilter.getFieldPath()}"`}
              </Label>
            </Field>
            <Field>
              <InternalLink
                onClick={() => setSelectedFieldPath('')}
              >
                Go back
              </InternalLink>
            </Field>
          </Field.Group>
          <KeyValueList>
            {selectedDataFilter.getAllowedValues().map(v => (
              <KeyValueItem
                term={JSON.stringify(v)}
              >
                <IconButton
                  type="delete"
                  iconSize="14"
                  onClick={e => { e.preventDefault(); removeDataFilter(v) }}
                />
              </KeyValueItem>
            ))}
          </KeyValueList>
        </>
      )}
      {dataFilters.hasDataFilters() && !selectedDataFilter && (
        <TruncatingKeyValueList>
          {dataFilters.getDataFilters().map(f => (
            <KeyValueItem
              term={displayDataFilter(f)}
            >
              <IconButton
                type="edit"
                iconSize="14"
                onClick={e => {
                  e.preventDefault()
                  setSelectedFieldPath(f.getFieldPath())
                }}
              />
              <IconButton
                type="delete"
                iconSize="14"
                onClick={e => {
                  e.preventDefault()
                  removeDataFilterByPath(f.getFieldPath())
                }}
              />
            </KeyValueItem>
          ))}
        </TruncatingKeyValueList>
      )}
      <FieldTripleSmallInputText>
        <Field>
          <Label htmlFor="field-path">Path</Label>
          <Input
            id="field-path"
            value={fieldPath}
            placeholder={fieldPathPlaceholder(allowedValueType)}
            onChange={e => setFieldPath(e.target.value)}
          />
        </Field>
        <Field>
          <Label htmlFor="allowed-value-type">Value type</Label>
          <Select
            id="allowed-value-type"
            value={allowedValueTypeOptions
              .filter(o => o.value === allowedValueType)[0]}
            options={allowedValueTypeOptions}
            onChange={e => setAllowedValueType(e.value)}
          />
        </Field>
        <Field>
          <Label htmlFor={`${allowedValueType}-allowed-value`}>Value</Label>
          {allowedValueType === 'string' && (
            <Input
              id="string-allowed-value"
              value={stringAllowedValue}
              placeholder="Ex: ItemUpgrade"
              onChange={e => setStringAllowedValue(e.target.value)}
            />
          )}
          {allowedValueType === 'empty' && (
            <Input
              id="empty-allowed-value"
              value={'""'}
              disabled
            />
          )}
          {allowedValueType === 'number' && (
            <Input
              type="number"
              id="number-allowed-value"
              value={numberAllowedValue}
              placeholder="Ex: 7"
              onChange={e => setNumberAllowedValue(e.target.value)}
            />
          )}
          {allowedValueType === 'boolean' && (
            <Select
              id="boolean-allowed-value"
              value={booleanAllowedValueOptions
                .filter(o => o.value === booleanAllowedValue)}
              options={booleanAllowedValueOptions}
              onChange={e => setBooleanAllowedValue(e.value)}
            />
          )}
        </Field>
      </FieldTripleSmallInputText>
      <div
        style={{
          display: 'flex',
          'flex-direction': 'row-reverse',
        }}
      >
        <Button
          type="outlined"
          style={{ 'margin-top': '0.6rem' }}
          onClick={handleAddDataFilter}
        >
          Add value to path list
        </Button>
      </div>
    </>
  </>
)

export default FilterEditor
