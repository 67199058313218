class Game {
  constructor(data) {
    Object.assign(this, data || {})
  }

  getID() {
    return this.id || ''
  }

  getName() {
    return this.name || ''
  }

  getIcon() {
    return this.icon || ''
  }

  hasQueries() {
    return (this.services || []).includes('queries')
  }
}

export default Game
