import styled from 'styled-components'

// WL Design System
import {
  BoxContainer,
} from 'wildlife-design-system'

const ListBoxContainer = styled(BoxContainer)`
  padding: 22px;

  & > p {
    margin-bottom: 0;
    margin-top: 10px;
  }
`

export default ListBoxContainer
