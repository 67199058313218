const iamHost = process.env.REACT_APP_IAM_BASE_URL

export default {
  getUser: async (userEmail, accessToken) => {
    const url = `${iamHost}/service_accounts/search?term=${userEmail}`
    const opts = {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
    const resp = await fetch(url, opts)
    if (resp.status === 401) {
      return { unauthenticated: true }
    }
    const [{ email, picture }] = (await resp.json()).results || []
    return { email, picture }
  },
}
