import QueryFilter from './QueryFilter'

class QueryRequestRecord {
  constructor(data) {
    const objectData = data || {}
    Object.assign(this, objectData, { objectData })
  }

  stringify() {
    return JSON.stringify(this.objectData)
  }

  getID() {
    return this.id || ''
  }

  getQueryID() {
    return this.query_id || ''
  }

  getFilterBefore() {
    return new QueryFilter(this.filter_before)
  }

  getFilterProposal() {
    return new QueryFilter(this.filter_proposal)
  }

  getAuthor() {
    return this.author || ''
  }

  getMotivation() {
    return this.motivation || ''
  }

  getReviewer() {
    return this.reviewer || ''
  }

  getRejectReason() {
    return this.reject_reason || ''
  }

  getRequestedAt() {
    return this.requested_at || ''
  }

  getReviewedAt() {
    return this.reviewed_at || ''
  }

  isCreation() {
    return this.getFilterBefore().isEmpty()
  }

  isDeletion() {
    return this.getFilterProposal().isEmpty()
  }

  wasAccepted() {
    return (this.reviewer_judgement || '') === 'accepted'
  }

  wasRejected() {
    return (this.reviewer_judgement || '') === 'rejected'
  }
}

export default QueryRequestRecord
