import mixpanel from 'mixpanel-browser'

mixpanel.init(process.env.REACT_APP_ANALYTICS_TOKEN)

const analytics = {
  button_pressed: () => {
    mixpanel.track('Button pressed')
  },
  add_person: email => {
    mixpanel.identify(email)
    mixpanel.people.set_once({
      Email: email,
      'First Login Date': new Date(),
    })
    mixpanel.people.set({
      Name: email.substring(0, email.indexOf('@')),
    })
  },
}

export default analytics
