// imports
import QueryFilter from './QueryFilter'
import { patch } from '../helpers'
import { now } from '../../util'

class QueryFilterUpdateRequest {
  constructor(data) {
    const objectData = data || {}
    Object.assign(this, objectData, { objectData })
  }

  stringify() {
    return JSON.stringify(this.objectData)
  }

  exists() {
    return (this.author || '') !== ''
  }

  getTime() {
    return this.time || now()
  }

  getAuthor() {
    return this.author || ''
  }

  getMotivation() {
    return this.motivation || ''
  }

  getFilter() {
    return new QueryFilter(this.filter)
  }

  isFilterEmpty() {
    return this.getFilter().isEmpty()
  }

  request(username, motivation, filter) {
    patch(this, {
      time: now(),
      author: username,
      filter: JSON.parse(filter.stringify()),
      motivation,
    })
  }
}

export default QueryFilterUpdateRequest
