import React from 'react'
import { Query } from '../../../objects/queries'

// components
import CreateEditPage from './CreateEditPage'

const CreatePage = ({
  game, form, isFormEmpty, clearForm,
  onSubmit: handleSubmit, history,
}) => (
  <CreateEditPage
    pageTitle="Create Query"
    game={game}
    query={new Query()}
    form={form}
    isFormEmpty={isFormEmpty}
    clearForm={clearForm}
    onSubmit={handleSubmit}
    history={history}
  />
)

export default CreatePage
