/* eslint-disable camelcase */
import { diffString } from 'json-diff'
import QueryDataFilter from './QueryDataFilter'
import { patch } from '../helpers'
import { Topaz } from './constants'

const findDataFilterByPath = (self, path) => self.getDataFilters()
  .reduce((acc, f, i) => (f.getFieldPath() === path ? i : acc), -1)

class QueryFilter {
  constructor(data) {
    const objectData = data || {}
    Object.assign(this, objectData, { objectData })
  }

  stringify() {
    return JSON.stringify(this.objectData)
  }

  getData() {
    return JSON.parse(this.stringify())
  }

  getNormalized() {
    return new QueryFilter({
      allowed_origins: this.getAllowedOrigins(),
      allowed_event_names: this.getAllowedEventNames(),
      data_filters: this.getDataFilters().map(f => f.getNormalized().getData()),
    })
  }

  getDifference(other) {
    const t = this.getNormalized().getData()
    const o = other.getNormalized().getData()
    return diffString(t, o, { color: false }) || ''
  }

  cloneForEditing() {
    return new QueryFilter(this.getData())
  }

  getAllowedOrigins() {
    return this.allowed_origins || []
  }

  setTopazAsOrigin() {
    patch(this, { allowed_origins: [Topaz] })
  }

  getAllowedEventNames() {
    return this.allowed_event_names || []
  }

  setAllowedEventNames(allowed_event_names) {
    patch(this, { allowed_event_names })
  }

  hasDataFilters() {
    return (this.data_filters || []).length > 0
  }

  countDataFilters() {
    return (this.data_filters || []).length
  }

  getDataFilters() {
    return (this.data_filters || []).map(f => new QueryDataFilter(f))
  }

  setDataFilters(dataFilters) {
    patch(this, {
      data_filters: dataFilters.map(f => JSON.parse(f.stringify())),
    })
  }

  getDataFilterByPath(path) {
    return this.getDataFilters().filter(f => f.getFieldPath() === path)[0]
  }

  isEmpty() {
    return [
      this.allowed_origins,
      this.allowed_event_names,
      this.data_filters,
    ].filter(a => (a || []).length > 0).length === 0
  }

  isOriginTopaz() {
    const a = this.getAllowedOrigins()
    return a.includes(Topaz) && a.length === 1
  }

  addDataFilter(path, value) {
    const dataFilters = this.data_filters || []
    const idx = findDataFilterByPath(this, path)
    if (idx === -1) {
      patch(this, {
        data_filters: [
          ...dataFilters,
          JSON.parse(QueryDataFilter.fromPathAndValue(path, value).stringify()),
        ],
      })
    } else {
      const dataFilter = new QueryDataFilter(dataFilters[idx])
      dataFilter.addAllowedValue(value)
      patch(this, {
        data_filters: [
          ...dataFilters.slice(0, idx),
          JSON.parse(dataFilter.stringify()),
          ...dataFilters.slice(idx + 1),
        ],
      })
    }
  }

  removeDataFilter(path, value) {
    const dataFilters = this.data_filters || []
    const idx = findDataFilterByPath(this, path)
    const dataFilter = new QueryDataFilter(dataFilters[idx])
    dataFilter.removeAllowedValue(value)
    const newDataFilter = []
    if (dataFilter.hasAllowedValues()) {
      newDataFilter.push(JSON.parse(dataFilter.stringify()))
    }
    patch(this, {
      data_filters: [
        ...dataFilters.slice(0, idx),
        ...newDataFilter,
        ...dataFilters.slice(idx + 1),
      ],
    })
  }

  removeDataFilterByPath(path) {
    patch(this, {
      data_filters: this.getDataFilters()
        .filter(f => f.getFieldPath() !== path)
        .map(f => JSON.parse(f.stringify())),
    })
  }
}

export default QueryFilter
