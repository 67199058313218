import React from 'react'
import styled from 'styled-components'
import { IconButton } from 'wildlife-design-system'
import { queryBasicInfoHTML } from './util'
import { prompt } from '../../../util'

const Flex = styled.div`
  display: flex;
  flex-direction: row-reverse;
  padding: 12px 0;
`

const editQueryPath = (gameID, queryID) =>
  // eslint-disable-next-line implicit-arrow-linebreak
  `/games/queries/edit?game_id=${gameID}&query_id=${queryID}`

const HeaderActions = ({
  game, user, query, history,
  onDeleteQuery,
}) => {
  const handleEditQuery = () => history.push(editQueryPath(game.getID(), query.getID()))

  const handleDeleteQuery = async () => {
    const title = 'Please enter your motivation to delete the query:'
    const html = queryBasicInfoHTML(query)
    const motivation = await prompt(title, html)
    if (motivation) {
      onDeleteQuery(query.getID(), motivation)
    }
  }

  return (
    <Flex>
      <div>
        {user.hasEditQuery(game) && (
          <IconButton
            type="edit"
            iconSize="14"
            onClick={handleEditQuery}
            disabled={query.isSynchronizing() || query.hasFilterUpdateRequest()}
          />
        )}
        {user.hasDeleteQuery(game) && (
          <IconButton
            type="delete"
            iconSize="14"
            onClick={handleDeleteQuery}
            disabled={query.isSynchronizing() || query.hasFilterUpdateRequest()}
          />
        )}
      </div>
    </Flex>
  )
}

export default HeaderActions
