import React from 'react'
import { Query, QueryRequestRecord } from '../../objects/queries'
import useQueryParams from '../useQueryParams'

const useQueries = (
  api, gameID, accessToken, username, insertQueryRequestRecord,
) => {
  const [queries, setQueries] = React.useState([])
  const selectedQueryID = useQueryParams().get('query_id')
  let selectedQuery = queries.filter(q => selectedQueryID === q.getID())
  if (selectedQuery.length > 0) {
    [selectedQuery] = selectedQuery
  } else {
    selectedQuery = new Query()
  }

  const lastUpdate = React.useRef({ time: 0 })
  const now = Date.now()
  if (now - lastUpdate.current.time > 1000 * 60 * 5) { // retrigger effect if more than 5min passed
    lastUpdate.current.time = now
  }
  React.useEffect(() => {
    const loadQueries = async () => {
      setQueries([])
      if (!gameID) {
        return
      }
      try {
        const resp = await api.listQueries(gameID, accessToken)
        setQueries((resp.queries || []).map(q => new Query(q)))
      } catch (e) {
        lastUpdate.current.time = 0 // force retrigger on next rendering
        // TODO: handle properly, give feedback to the user
      }
    }
    loadQueries()
  }, [api, gameID, accessToken, lastUpdate.current.time])

  const findQuery = query => queries
    .reduce((acc, q, i) => (q.getID() === query.getID() ? i : acc), -1)

  const replaceQuery = (query, newQuery) => {
    const idx = findQuery(query)
    if (idx === -1) return
    setQueries([
      ...queries.slice(0, idx),
      newQuery,
      ...queries.slice(idx + 1),
    ])
  }

  const insertQuery = query => setQueries([
    ...queries,
    query,
  ])

  const removeQuery = query => {
    const idx = findQuery(query)
    if (idx === -1) return
    setQueries([
      ...queries.slice(0, idx),
      ...queries.slice(idx + 1),
    ])
  }

  const restoreQueries = () => setQueries([...queries])

  const createQuery = async (formQuery, motivation, onSuccess) => {
    const newQuery = formQuery.cloneForSynchronization()
    newQuery.requestCreation(username, motivation)
    try {
      insertQuery(newQuery)
      const q = JSON.parse(formQuery.stringify())
      const resp = await api
        .requestQueryCreation(gameID, q, motivation, accessToken)
      insertQuery(new Query(resp))
      onSuccess()
    } catch (e) {
      restoreQueries()
    }
  }

  const editQuery = async (formQuery, motivation, onSuccess) => {
    const queryID = formQuery.getID()
    const query = queries.filter(q => q.getID() === queryID)[0]
    if (!query) return

    const newQuery = query.cloneForSynchronization()
    newQuery.requestUpdate(formQuery, username, motivation)
    try {
      replaceQuery(query, newQuery)
      const q = JSON.parse(formQuery.stringify())
      const resp = await api
        .requestQueryUpdate(gameID, queryID, q, motivation, accessToken)
      replaceQuery(query, new Query(resp))
      onSuccess()
    } catch (e) {
      restoreQueries()
    }
  }

  const deleteQuery = async (queryID, motivation) => {
    const query = queries.filter(q => q.getID() === queryID)[0]
    if (!query) return

    const newQuery = query.cloneForSynchronization()
    newQuery.requestDeletion(username, motivation)
    try {
      replaceQuery(query, newQuery)
      const resp = await api
        .requestQueryDeletion(gameID, queryID, motivation, accessToken)
      replaceQuery(query, new Query(resp))
    } catch (e) {
      restoreQueries()
    }
  }

  const acceptQueryRequest = async queryID => {
    const query = queries.filter(q => q.getID() === queryID)[0]
    if (!query) return

    if (query.isFilterUpdateRequestDeletion()) {
      try {
        removeQuery(query)
        const resp = await api
          .acceptQueryRequest(gameID, queryID, accessToken)
        insertQueryRequestRecord(new QueryRequestRecord(resp.request_record))
      } catch (e) {
        restoreQueries()
      }
      return
    }

    const newQuery = query.cloneForSynchronization()
    newQuery.acceptRequest()
    try {
      replaceQuery(query, newQuery)
      const resp = await api
        .acceptQueryRequest(gameID, queryID, accessToken)
      replaceQuery(query, new Query(resp.query_after))
      insertQueryRequestRecord(new QueryRequestRecord(resp.request_record))
    } catch (e) {
      restoreQueries()
    }
  }

  const rejectQueryRequest = async (queryID, reason) => {
    const query = queries.filter(q => q.getID() === queryID)[0]
    if (!query) return

    if (query.isFilterUpdateRequestCreation()) {
      try {
        removeQuery(query)
        const resp = await api
          .rejectQueryRequest(gameID, queryID, reason, accessToken)
        insertQueryRequestRecord(new QueryRequestRecord(resp.request_record))
      } catch (e) {
        restoreQueries()
      }
      return
    }

    const newQuery = query.cloneForSynchronization()
    newQuery.rejectRequest()
    try {
      replaceQuery(query, newQuery)
      const resp = await api
        .rejectQueryRequest(gameID, queryID, reason, accessToken)
      replaceQuery(query, new Query(resp.query_after))
      insertQueryRequestRecord(new QueryRequestRecord(resp.request_record))
    } catch (e) {
      restoreQueries()
    }
  }

  return {
    queries,
    query: selectedQuery,
    createQuery,
    editQuery,
    deleteQuery,
    acceptQueryRequest,
    rejectQueryRequest,
  }
}

export default useQueries
