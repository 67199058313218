/* eslint-disable implicit-arrow-linebreak */
import { patch } from '../helpers'

// private helpers
const getAllowedValuesExcept = (self, value) =>
  self.getAllowedValues().filter(v => v !== value)

class QueryDataFilter {
  constructor(data) {
    const objectData = data || {}
    Object.assign(this, objectData, { objectData })
  }

  static fromPathAndValue(path, value) {
    return new QueryDataFilter({
      field_path: path.split('.'),
      allowed_values: [value],
    })
  }

  stringify() {
    return JSON.stringify(this.objectData)
  }

  getData() {
    return JSON.parse(this.stringify())
  }

  getNormalized() {
    return new QueryDataFilter({
      field_path: this.field_path || [],
      allowed_values: this.getAllowedValues(),
    })
  }

  getFieldPath() {
    return (this.field_path || []).join('.')
  }

  getAllowedValues() {
    return (this.allowed_values || [])
  }

  hasAllowedValues() {
    return this.getAllowedValues().length > 0
  }

  hasAllowedValue(value) {
    return this.getAllowedValues().filter(v => v === value).length > 0
  }

  addAllowedValue(value) {
    patch(this, {
      allowed_values: [
        ...getAllowedValuesExcept(this, value),
        value,
      ],
    })
  }

  removeAllowedValue(value) {
    patch(this, {
      allowed_values: getAllowedValuesExcept(this, value),
    })
  }
}

export default QueryDataFilter
