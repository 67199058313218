import React from 'react'

import {
  PageDetailsTitle,
  SmallText,
} from 'wildlife-design-system'

import { FlexibleColumnPair, ListBoxContainerParagraph } from '../../styles'
import HeaderActions from './HeaderActions'
import UsernamesAndDates from '../UsernamesAndDates'

const QueryHeader = ({
  game, user, query, history,
  onDeleteQuery: handleDeleteQuery,
}) => (
  <>
    <FlexibleColumnPair>
      <div>
        <PageDetailsTitle>{query.getName()}</PageDetailsTitle>
        <SmallText>{`ID: ${query.getID()}`}</SmallText>
      </div>
      <HeaderActions
        game={game}
        user={user}
        query={query}
        history={history}
        onDeleteQuery={handleDeleteQuery}
      />
    </FlexibleColumnPair>
    {query.getDescription() && (
      <ListBoxContainerParagraph>
        {query.getDescription()}
      </ListBoxContainerParagraph>
    )}
    {!query.isFilterUpdateRequestCreation() && (
      <UsernamesAndDates query={query} />
    )}
  </>
)

export default QueryHeader
