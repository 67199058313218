export const nameFromEmailOrEmail = email => {
  const [rawName, domain] = (email || '').split('@')
  const name = !rawName ? email : (rawName
    .split('.')
    .filter(n => n !== '')
    .map(n => (n === 'ext' ? '(Ext)' : `${n[0].toUpperCase()}${n.substring(1)}`))
    .join(' ') || email)
  return (domain || '') !== ''
    && domain !== 'wildlifestudios.com'
    && !name.endsWith('(Ext)')
    ? `${name} (Ext)`
    : name
}

export const nameAndEmailFromEmailOrName = email => {
  const name = nameFromEmailOrEmail(email)
  if (name === email) return name
  if (!name.endsWith('(Ext)')) return `${name} (${email})`
  return `${name.substring(0, name.length - 1)}, ${email})`
}
