import React from 'react'
import { User } from '../../objects'

const useUser = (iam, api, userEmail, accessToken) => {
  const [user, setUser] = React.useState(new User())
  const [unauthenticated, setUnauthenticated] = React.useState(false)

  const lastUpdate = React.useRef({ time: 0 })
  const now = Date.now()
  if (now - lastUpdate.current.time > 1000 * 60 * 5) { // retrigger effect if more than 5min passed
    lastUpdate.current.time = now
  }
  React.useEffect(() => {
    const loadUser = async () => {
      setUnauthenticated(false)
      try {
        const iamUser = await iam.getUser(userEmail, accessToken)
        if (iamUser.unauthenticated) {
          setUnauthenticated(true)
          return
        }
        const permissions = await api.listUserPermissions(accessToken)
        setUser(new User({ ...iamUser, permissions }))
        setUnauthenticated(false)
      } catch (e) {
        lastUpdate.current.time = 0 // force retrigger on next rendering
        // TODO: handle properly, give feedback to the user
      }
    }
    loadUser()
  }, [iam, api, userEmail, accessToken, lastUpdate.current.time])

  return {
    user,
    unauthenticated,
  }
}

export default useUser
