const hasPermission = (permissions, game, gameHasService, operation) => {
  if (!game) return false
  const gameID = game.getID()
  return gameHasService.bind(game)()
    && permissions
    && permissions[gameID]
    && permissions[gameID].includes(operation)
}

class User {
  constructor(data) {
    Object.assign(this, data || {})
  }

  getEmail() {
    return this.email || ''
  }

  getPicture() {
    return this.picture || ''
  }

  hasCreateQuery(game) {
    return hasPermission(this.permissions, game, game && game.hasQueries, 'RequestQueryCreation')
  }

  hasEditQuery(game) {
    return hasPermission(this.permissions, game, game && game.hasQueries, 'RequestQueryUpdate')
  }

  hasDeleteQuery(game) {
    return hasPermission(this.permissions, game, game && game.hasQueries, 'RequestQueryDeletion')
  }

  hasAcceptQueryRequest(game) {
    return hasPermission(this.permissions, game, game && game.hasQueries, 'AcceptQueryRequest')
  }

  hasRejectQueryRequest(game) {
    return hasPermission(this.permissions, game, game && game.hasQueries, 'RejectQueryRequest')
  }

  hasListQueries(game) {
    return hasPermission(this.permissions, game, game && game.hasQueries, 'ListQueries')
  }

  hasListQueryRequestRecords(game) {
    return hasPermission(this.permissions, game, game && game.hasQueries, 'ListQueryRequestRecords')
  }

  hasListDiscardedLogs(game) {
    return hasPermission(this.permissions, game, game && game.hasQueries, 'ListDiscardedLogs')
  }
}

export default User
