import React from 'react'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'
import {
  Button,
  ButtonGroup,
  LocalMenu as WLSLocalMenu,
} from 'wildlife-design-system'

import {
  CREATE_QUERY,
  LIST_QUERIES,
  LIST_DISCARDED_LOGS,
  LIST_QUERY_REQUEST_RECORDS,
} from '../../constants'
import {
  createQueryPath,
  listQueriesPath,
  listDiscardedLogsPath,
  listQueryRequestRecordsPath,
} from '../../paths'

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
`

const LocalMenu = ({ game, user, history }) => {
  const hasButtons = user.hasListQueries(game)
    || user.hasListDiscardedLogs(game)
    || user.hasListQueryRequestRecords(game)

  const { pathname } = useLocation()
  const isQueries = listQueriesPath(game).startsWith(`${pathname}?`)
  const isDiscardedLogs = listDiscardedLogsPath(game).startsWith(`${pathname}?`)
  const isQueryRequestRecords = listQueryRequestRecordsPath(game).startsWith(`${pathname}?`)

  return (
    <WLSLocalMenu
      pageTitle="Angel Queries"
    >
      <div>
        {hasButtons && (
          <ButtonGroup>
            {user.hasListQueries(game) && (
              <ButtonGroup.Button
                active={isQueries}
                onClick={() => history.push(listQueriesPath(game))}
              >
                {LIST_QUERIES.slice(5)}
              </ButtonGroup.Button>
            )}
            {user.hasListDiscardedLogs(game) && (
              <ButtonGroup.Button
                active={isDiscardedLogs}
                onClick={() => history.push(listDiscardedLogsPath(game))}
              >
                {LIST_DISCARDED_LOGS.slice(5)}
              </ButtonGroup.Button>
            )}
            {user.hasListQueryRequestRecords(game) && (
              <ButtonGroup.Button
                active={isQueryRequestRecords}
                onClick={() => history.push(listQueryRequestRecordsPath(game))}
              >
                {LIST_QUERY_REQUEST_RECORDS.slice(5)}
              </ButtonGroup.Button>
            )}
          </ButtonGroup>
        )}
      </div>
      {user.hasCreateQuery(game) && (
        <ButtonContainer>
          <Button
            onClick={() => history.push(createQueryPath(game))}
          >
            {CREATE_QUERY}
          </Button>
        </ButtonContainer>
      )}
    </WLSLocalMenu>
  )
}

export default LocalMenu
