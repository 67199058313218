import { Topaz } from './constants'

const allPaths = root => {
  const paths = {}
  const dfs = (node, path) => {
    if (Array.isArray(node)) {
      node.forEach(o => dfs(o, path))
    } else if (typeof node === 'object' && node !== null) {
      Object.keys(node).forEach(k => dfs(node[k], path ? `${path}.${k}` : k))
    } else if (path !== '' && node !== null && node !== undefined) {
      paths[path] = true
    }
  }
  dfs(root, '')
  return Object.keys(paths)
}

const valuesAtPath = (root, fullPath) => {
  let values = []
  const push = v => {
    if (Array.isArray(v) || typeof v === 'object' || v === undefined) {
      return
    }
    values = [
      ...values.filter(x => x !== v),
      v,
    ]
  }

  const dfs = (node, path) => {
    if (path.length === 0) {
      if (Array.isArray(node)) {
        node.forEach(nodeValue => push(nodeValue))
      } else {
        push(node)
      }
      return
    }

    const [next] = path
    if (Array.isArray(node)) {
      node.forEach(o => dfs(o, path))
    } else if (
      typeof node === 'object'
      && node !== null
      && Object.keys(node).includes(next)
    ) {
      dfs(node[next], path.slice(1))
    }
  }

  dfs(root, fullPath)
  return values
}

class Log {
  constructor(data) {
    const objectData = data || {}
    Object.assign(this, objectData, { objectData })
  }

  getOrigin() {
    return this.origin || ''
  }

  isOriginTopaz() {
    return this.getOrigin() === Topaz
  }

  getEventName() {
    return this.event_name || ''
  }

  getDataAsMap() {
    return (this.data || [])
      .reduce((acc, d) => ({ ...acc, [d.name]: d.value }), {})
  }

  getPathList() {
    return allPaths(this.getDataAsMap())
  }

  getValuesAtPath(path) {
    return valuesAtPath(this.getDataAsMap(), path.split('.'))
  }
}

export default Log
