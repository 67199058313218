import React from 'react'
import { Game } from '../../objects'
import useQueryParams from '../useQueryParams'

const useGames = (api, accessToken) => {
  const [games, setGames] = React.useState([])
  const selectedGameID = useQueryParams().get('game_id')
  let selectedGame = games.filter(g => selectedGameID === g.getID())
  if (selectedGame.length > 0) {
    [selectedGame] = selectedGame
  } else {
    selectedGame = new Game()
  }

  const lastUpdate = React.useRef({ time: 0 })
  const now = Date.now()
  if (now - lastUpdate.current.time > 1000 * 60 * 5) { // retrigger effect if more than 5min passed
    lastUpdate.current.time = now
  }
  React.useEffect(() => {
    const listGames = async () => {
      try {
        setGames((await api.listGames(accessToken)).map(g => new Game(g)))
      } catch (e) {
        lastUpdate.current.time = 0 // force retrigger on next rendering
        // TODO: handle properly, give feedback to the user
      }
    }
    listGames()
  }, [api, accessToken, lastUpdate.current.time])

  return {
    games,
    game: selectedGame,
  }
}

export default useGames
