import React from 'react'
import DiscardedLog from '../../objects/queries/DiscardedLog'
import useQueryParams from '../useQueryParams'

const useDiscardedLogs = (api, gameID, accessToken) => {
  const [discardedLogs, setDiscardedLogs] = React.useState([])
  const selectedDiscardedLogID = useQueryParams().get('discarded_log_id')
  let selectedDiscardedLog = discardedLogs.filter(l => selectedDiscardedLogID === l.getID())
  if (selectedDiscardedLog.length > 0) {
    [selectedDiscardedLog] = selectedDiscardedLog
  } else {
    selectedDiscardedLog = new DiscardedLog()
  }

  const lastUpdate = React.useRef({ time: 0 })
  const now = Date.now()
  if (now - lastUpdate.current.time > 1000 * 60 * 5) { // retrigger effect if more than 5min passed
    lastUpdate.current.time = now
  }
  React.useEffect(() => {
    const loadDiscardedLogs = async () => {
      setDiscardedLogs([])
      if (!gameID) {
        return
      }
      try {
        const resp = await api.listDiscardedLogs(gameID, accessToken)
        setDiscardedLogs((resp.logs || []).map(l => new DiscardedLog(l)))
      } catch (e) {
        lastUpdate.current.time = 0 // force retrigger on next rendering
        // TODO: handle properly, give feedback to the user
      }
    }
    loadDiscardedLogs()
  }, [api, gameID, accessToken, lastUpdate.current.time])

  return {
    discardedLogs,
    discardedLog: selectedDiscardedLog,
  }
}

export default useDiscardedLogs
