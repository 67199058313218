/* eslint class-methods-use-this: ["error", { "exceptMethods": ["getReferer"] }] */
import analytics from './analytics'

const iamBaseUrl = process.env.REACT_APP_IAM_BASE_URL || ''
const { location } = window
const storage = window.localStorage

class Auth {
  constructor() {
    this.oauth2Key = 'oauth2Middleware'
    this.email = 'email'
  }

  setToken(token) {
    storage.setItem(this.oauth2Key, token)
  }

  setEmail(email) {
    storage.setItem(this.email, email)
  }

  removeToken() {
    storage.removeItem(this.oauth2Key)
  }

  removeEmail() {
    storage.removeItem(this.email)
  }

  logout() {
    this.removeToken()
    this.removeEmail()
  }

  getToken() {
    return new URL(location).searchParams.get('accessToken') || storage.getItem(this.oauth2Key)
  }

  getEmail() {
    return new URL(location).searchParams.get('email') || storage.getItem(this.email)
  }

  isAuthenticated() {
    return !!this.getToken()
  }

  getReferer() {
    return new URL(window.location).searchParams.get('referer')
  }

  authenticate(history) {
    const referer = encodeURIComponent(
      `${location.origin}/login?referer=${encodeURIComponent(this.getReferer())}`,
    )

    if (!this.getToken()) {
      window.location = `${iamBaseUrl}/sso?referer=${referer}`
    } else {
      this.setToken(this.getToken())
      this.setEmail(this.getEmail())
      analytics.add_person(this.getEmail())

      if (this.getReferer()) history.push(this.getReferer())
    }
  }
}

export default new Auth()
