import styled from 'styled-components'

const ListRow = styled.tr(
  ({ selected }) => `
    ${(selected && `
      background-color: rgba(150, 150, 150, 0.15);
    `) || ''}
  `,
)

export default ListRow
