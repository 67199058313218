import React from 'react'
import styled from 'styled-components'
import { CircularProgress } from '@material-ui/core'

const CenterDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 600px;
`

const LoadingPage = () => (
  <CenterDiv><CircularProgress size={80} thickness={3.6} /></CenterDiv>
)

export default LoadingPage
