import React from 'react'
import styled from 'styled-components'

import {
  Button,
  SmallText,
} from 'wildlife-design-system'

import { confirm, prompt } from '../../../util'
import {
  queryBasicInfoHTML,
  pendingQueryActionText,
} from './util'

const Flex = styled.div`
  display: flex;
  justify-content: center;
`

const ActionButton = styled(Button)`
  margin: 22px 8px 0 8px;
`

const Or = styled(SmallText)`
  color: #B4B4B4;
  font-family: "Roboto Condensed",Helvetica,Arial,sans-serif;
  font-size: 14px;
  line-height: 17px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 22px;
`

const FooterActions = ({
  game, user, query,
  onAcceptQueryRequest,
  onRejectQueryRequest,
}) => {
  const handleAcceptQueryRequest = async () => {
    const title = `Approve query ${pendingQueryActionText(query)}?`
    const html = queryBasicInfoHTML(query)
    if (await confirm(title, html)) {
      onAcceptQueryRequest(query.getID())
    }
  }

  const handleRejectQueryRequest = async () => {
    const title = `Please enter your reason to reject the query ${pendingQueryActionText(query)}:`
    const html = queryBasicInfoHTML(query)
    const reason = await prompt(title, html)
    if (reason) {
      onRejectQueryRequest(query.getID(), reason)
    }
  }

  const hasBoth = query.hasFilterUpdateRequest()
    && user.hasAcceptQueryRequest(game)
    && user.hasRejectQueryRequest(game)

  return (
    <Flex>
      {user.hasAcceptQueryRequest(game)
      && query.hasFilterUpdateRequest() && (
        <ActionButton
          onClick={handleAcceptQueryRequest}
          disabled={query.isSynchronizing()}
        >
          Approve
        </ActionButton>
      )}
      {hasBoth && <Or>or</Or>}
      {user.hasRejectQueryRequest(game)
      && query.hasFilterUpdateRequest() && (
        <ActionButton
          profile="error"
          onClick={handleRejectQueryRequest}
          disabled={query.isSynchronizing()}
        >
          Reject
        </ActionButton>
      )}
    </Flex>
  )
}

export default FooterActions
