/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-param-reassign */
import React from 'react'
import { v4 as uuidv4 } from 'uuid'

// WL Design System
import {
  FormLayout, Label, Input, Textarea, SectionTitle,
  Button, Paragraph, SmallText, InternalLink,
} from 'wildlife-design-system'

import { alert, confirm, prompt } from '../../../util'
import { Query, QueryFilter } from '../../../objects/queries'
import { PRODUCT_TITLE } from '../../../constants'
import UsernamesAndDates from '../UsernamesAndDates'
import FilterSection from './FilterSection'

const idSectionName = query =>
  `${query.getID() ? 'Edit ' : ''}Query Identification`
const motivationPrompt = query =>
  `Please enter your motivation for this ${query.getID() ? 'change on the query filter' : 'query'}:`
const getNameWithOrigin = (origin, curName) => {
  const prefix = origin === 'server' ? '[Server] ' : '[Client] '
  if (
    curName.startsWith('[Client] ')
    || curName.startsWith('[client] ')
    || curName.startsWith('[Server] ')
    || curName.startsWith('[server] ')
  ) {
    return prefix + curName.substring(9)
  }
  if (
    curName.startsWith('[Clients] ')
    || curName.startsWith('[clients] ')
    || curName.startsWith('[Servers] ')
    || curName.startsWith('[servers] ')
  ) {
    return prefix + curName.substring(10)
  }
  return prefix + curName
}

const CreateEditPage = ({
  pageTitle, game, query, form, isFormEmpty, clearForm,
  onSubmit, history,
}) => {
  const [name, setNameState] = React.useState(form.name || query.getName())
  const setName = v => {
    form.name = v
    setNameState(v)
  }

  const [
    description, setDescriptionState,
  ] = React.useState(form.description || query.getDescription())
  const setDescription = v => {
    form.description = v
    setDescriptionState(v)
  }

  const [
    filterOptIn, setFilterOptInState,
  ] = React.useState(form.filterOptIn || !query.getID())
  const setFilterOptIn = opt => {
    form.filterOptIn = opt
    setFilterOptInState(opt)
  }
  const optInForFilter = () => setFilterOptIn(true)
  const optOutForFilter = query.getID() ? () => setFilterOptIn(false) : null

  // allowed origins
  const getOrigin = () =>
    form.origin || (query.getID() && (query.isOriginTopaz() ? 'client' : 'server')) || ''
  const setOrigin = o => {
    form.origin = o
    setName(getNameWithOrigin(o, name))
  }

  // allowed event names
  const [
    eventNames, setEventNamesState,
  ] = React.useState(form.allowedEventNames || query.getAllowedEventNames())
  const addEventName = newEventName => {
    form.allowedEventNames = [
      ...eventNames.filter(n => n !== newEventName),
      newEventName,
    ].filter(n => n !== '')
    setEventNamesState(form.allowedEventNames)
  }
  const removeEventName = eventNameToRemove => {
    form.allowedEventNames = eventNames.filter(n => n !== eventNameToRemove)
    setEventNamesState(form.allowedEventNames)
  }
  const setEventNames = en => {
    form.allowedEventNames = en
    setEventNamesState(en)
  }
  const [eventName, setEventNameState] = React.useState(form.eventName || '')
  const setEventName = n => { form.eventName = n; setEventNameState(n) }
  const handleAddEventName = () => {
    addEventName(eventName)
    setEventName('')
  }

  // data filters
  const [fieldPath, setFieldPathState] = React.useState(form.fieldPath || '')
  const setFieldPath = p => { form.fieldPath = p; setFieldPathState(p) }
  const [
    allowedValueType, setAllowedValueTypeState,
  ] = React.useState(form.allowedValueType || 'string')
  const setAllowedValueType = t => {
    form.allowedValueType = t
    setAllowedValueTypeState(t)
  }
  const [
    stringAllowedValue, setStringAllowedValueState,
  ] = React.useState(form.stringAllowedValue || '')
  const setStringAllowedValue = s => {
    form.stringAllowedValue = s
    setStringAllowedValueState(s)
  }
  const [
    numberAllowedValue, setNumberAllowedValueState,
  ] = React.useState(form.numberAllowedValue || '')
  const setNumberAllowedValue = n => {
    form.numberAllowedValue = n
    setNumberAllowedValueState(n)
  }
  const [
    booleanAllowedValue, setBooleanAllowedValueState,
  ] = React.useState(
    Object.keys(form).includes('booleanAllowedValue')
      ? !!form.booleanAllowedValue
      : true,
  )
  const setBooleanAllowedValue = b => {
    form.booleanAllowedValue = b
    setBooleanAllowedValueState(b)
  }
  const [
    dataFilters, setDataFiltersState,
  ] = React.useState(form.dataFilters || query.getFilter().cloneForEditing())
  const setDataFilters = f => {
    form.dataFilters = f
    setDataFiltersState(f)
  }
  const handleAddDataFilter = () => {
    const path = fieldPath.split('.').filter(p => p !== '').join('.')
    if (path === '') return
    let value
    if (allowedValueType === 'string') {
      value = stringAllowedValue
    } else if (allowedValueType === 'empty') {
      value = ''
    } else if (allowedValueType === 'number' && numberAllowedValue !== '') {
      value = Number.parseFloat(numberAllowedValue)
    } else if (allowedValueType === 'boolean') {
      value = booleanAllowedValue
    } else {
      return
    }
    dataFilters.addDataFilter(path, value)
    setDataFilters(dataFilters.cloneForEditing())
    setFieldPath('')
    setAllowedValueType('string')
    setStringAllowedValue('')
    setNumberAllowedValue('')
    setBooleanAllowedValue(true)
  }
  const removeDataFilterByPath = p => {
    dataFilters.removeDataFilterByPath(p)
    setDataFilters(dataFilters.cloneForEditing())
  }
  const [
    selectedFieldPath, setSelectedFieldPathState,
  ] = React.useState(form.selectedFieldPath || '')
  const setSelectedFieldPath = p => {
    form.selectedFieldPath = p
    setSelectedFieldPathState(form.selectedFieldPath)
  }
  const removeDataFilter = v => {
    dataFilters.removeDataFilter(selectedFieldPath, v)
    const f = dataFilters.cloneForEditing()
    setDataFilters(f)
    if (!f.getDataFilterByPath(selectedFieldPath)) {
      setSelectedFieldPath('')
    }
  }
  const selectedDataFilter = dataFilters.getDataFilterByPath(selectedFieldPath)

  const buildFilter = () => {
    const f = new QueryFilter()
    if (filterOptIn) {
      if (getOrigin() === 'client') {
        f.setTopazAsOrigin()
      }
      f.setAllowedEventNames(eventNames)
      f.setDataFilters(dataFilters.getDataFilters())
    }
    return f
  }

  const validationRules = [
    {
      isValid: () => name !== '',
    },
    {
      isValid: () => !filterOptIn || getOrigin() !== '',
    },
    {
      isValid: () => !filterOptIn || query.hasFilterDifference(buildFilter()),
    },
    {
      isValid: () => !filterOptIn || eventNames.length > 0 || dataFilters.hasDataFilters(),
      errorMsg: 'At least one event name filter or one data filter must be specified. Did you forget to add something?',
    },
  ]
  const validateForm = () => (
    validationRules.reduce(
      (acc, rule) => acc || (!rule.isValid() && rule) || undefined,
      undefined,
    ) || true
  )

  const handleSubmit = async e => {
    e.preventDefault()

    const rule = validateForm()
    if (rule !== true) {
      if (rule.errorMsg) {
        alert('Query Validation Error', rule.errorMsg)
      }
      return
    }

    // ensure origin in name prefix before popups
    const nameWithOrigin = getNameWithOrigin(getOrigin(), name)
    setName(nameWithOrigin)

    let motivation
    if (query.getID() && !filterOptIn) {
      if (!(await confirm('Save query identification?'))) {
        return
      }
    } else {
      const title = motivationPrompt(query)
      motivation = await prompt(title)
      if (!motivation) {
        return
      }
    }

    const q = new Query().cloneForSynchronization()
    q.setID(query.getID() || uuidv4())
    q.setName(nameWithOrigin)
    q.setDescription(description)
    q.setFilter(buildFilter())

    onSubmit(q, motivation, clearForm)
    history.push(`/games/queries?game_id=${game.getID()}&query_id=${q.getID()}`)
  }

  const validation = validateForm()
  const disabled = validation !== true && !validation.errorMsg

  const [choseToKeepChanges, setChoseToKeepChanges] = React.useState(isFormEmpty())
  const chooseToKeepChanges = () => setChoseToKeepChanges(true)

  return (
    <FormLayout
      productTitle={PRODUCT_TITLE}
      pageTitle={`${game.getName()}: ${pageTitle}`}
      submitLabel={filterOptIn ? 'Submit for review' : 'Save query identification'}
      onSubmit={handleSubmit}
      disabled={disabled}
    >
      { // keep or discard previous unsubmitted changes
        !choseToKeepChanges && (
          <>
            <Paragraph>
              Your last unsubmitted changes were kept for you. Do you want to discard them?
            </Paragraph>
            <Button
              profile="error"
              onClick={clearForm}
              style={{ 'margin-right': '0.6rem' }}
            >
              Reset form
            </Button>
            <SmallText>or</SmallText>
            <InternalLink
              type="small"
              onClick={chooseToKeepChanges}
              style={{ 'padding-left': '0.3rem' }}
            >
              Keep
            </InternalLink>
          </>
        )
      }

      { // display current basic info (edit-only)
        query.getID() && (
          <>
            <SectionTitle>{query.getName()}</SectionTitle>
            <SmallText>{`ID: ${query.getID()}`}</SmallText>
            <UsernamesAndDates query={query} />
          </>
        )
      }

      <SectionTitle>{idSectionName(query)}</SectionTitle>
      <>
        <Label htmlFor="name" required>Query Name</Label>
        <Input
          id="name"
          value={name}
          onChange={e => setName(e.target.value)}
          placeholder="Ex: [Server] Gems Conversions To Pay For Coins"
        />
        <Label htmlFor="description">Query Description</Label>
        <Textarea
          id="description"
          value={description}
          onChange={e => setDescription(e.target.value)}
          placeholder="Ex: This query brings all server logs related to the times when the player claimed Bullseye Rewards within the game."
        />
      </>

      <FilterSection
        query={query}
        form={form}
        filterOptIn={filterOptIn}
        optInForFilter={optInForFilter}
        optOutForFilter={optOutForFilter}
        setOrigin={setOrigin}
        getOrigin={getOrigin}
        eventNames={eventNames}
        removeEventName={removeEventName}
        setEventNames={setEventNames}
        eventName={eventName}
        setEventName={setEventName}
        handleAddEventName={handleAddEventName}
        selectedDataFilter={selectedDataFilter}
        setSelectedFieldPath={setSelectedFieldPath}
        dataFilters={dataFilters}
        removeDataFilter={removeDataFilter}
        removeDataFilterByPath={removeDataFilterByPath}
        fieldPath={fieldPath}
        allowedValueType={allowedValueType}
        setFieldPath={setFieldPath}
        setAllowedValueType={setAllowedValueType}
        stringAllowedValue={stringAllowedValue}
        setStringAllowedValue={setStringAllowedValue}
        numberAllowedValue={numberAllowedValue}
        setNumberAllowedValue={setNumberAllowedValue}
        booleanAllowedValue={booleanAllowedValue}
        setBooleanAllowedValue={setBooleanAllowedValue}
        setDataFilters={setDataFilters}
        handleAddDataFilter={handleAddDataFilter}
      />
    </FormLayout>
  )
}

export default CreateEditPage
