import React from 'react'
import styled from 'styled-components'

// WL Design System
import {
  InputCode,
  Alert,
} from 'wildlife-design-system'

import { displayDate, nameAndEmailFromEmailOrName } from '../../../util'

const StyledAlert = styled.div`
  margin-top: 15px;

  & > div {
    grid-template-columns: 5% auto 5%;
  }
`

const RequestOperation = styled.b`
  padding: 0 3px;
  font-style: bold;
`

const Motivation = styled.div`
  padding-top: 5px;
  font-style: italic;
`

const StyledInputCode = styled(InputCode)`
  margin-top: 15px;
`

const RequestAlert = ({ query }) => {
  const req = query.getFilterUpdateRequest()
  const name = nameAndEmailFromEmailOrName(req.getAuthor())
  // eslint-disable-next-line no-nested-ternary
  const op = query.isFilterUpdateRequestCreation()
    ? 'the creation'
    : (query.isFilterUpdateRequestDeletion()
      ? 'the deletion'
      : 'an update on the filters')

  return (
    <StyledAlert>
      <Alert>
        {`${name} requested`}
        <RequestOperation>{op}</RequestOperation>
        {`of this query on ${displayDate(req.getTime())}, with the following motivation:`}
        <Motivation>{`"${req.getMotivation()}"`}</Motivation>
      </Alert>
    </StyledAlert>
  )
}

const presentQueryFilters = query => {
  const stringify = o => JSON.stringify(o, undefined, 2)

  const fb = query.getFilter()
  const sb = stringify(fb.getData())
  if (!query.hasFilterUpdateRequest()) {
    return `\n${sb}\n`
  }

  const fa = query.getProposedFilter()
  const sa = stringify(fa.getData())
  const diff = fb.getDifference(fa)
  return `\n// before:\n${sb}\n\n// proposed changes:\n${diff}\n// after:\n${sa}\n`
}

const QueryFilter = ({ query }) => (
  <>
    {query.hasFilterUpdateRequest() && <RequestAlert query={query} />}
    <StyledInputCode
      readOnly
      mode="json"
      width="100%"
      value={presentQueryFilters(query)}
    />
  </>
)

export default QueryFilter
