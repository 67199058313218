import React from 'react'
import { QueryRequestRecord } from '../../objects/queries'
import useQueryParams from '../useQueryParams'

const useQueryRequestRecords = (api, gameID, accessToken) => {
  const [queryRequestRecords, setQueryRequestRecords] = React.useState([])
  const selectedQueryRequestRecordID = useQueryParams().get('query_request_record_id')
  let selectedQueryRequestRecord = queryRequestRecords
    .filter(q => selectedQueryRequestRecordID === q.getID())
  if (selectedQueryRequestRecord.length > 0) {
    [selectedQueryRequestRecord] = selectedQueryRequestRecord
  } else {
    selectedQueryRequestRecord = new QueryRequestRecord()
  }

  const lastUpdate = React.useRef({ time: 0 })
  const now = Date.now()
  if (now - lastUpdate.current.time > 1000 * 60 * 5) { // retrigger effect if more than 5min passed
    lastUpdate.current.time = now
  }
  React.useEffect(() => {
    const loadQueryRequestRecords = async () => {
      setQueryRequestRecords([])
      if (!gameID) {
        return
      }
      try {
        const resp = await api.listQueryRequestRecords(gameID, accessToken)
        setQueryRequestRecords((resp.records || []).map(q => new QueryRequestRecord(q)))
      } catch (e) {
        lastUpdate.current.time = 0 // force retrigger on next rendering
        // TODO: handle properly, give feedback to the user
      }
    }
    loadQueryRequestRecords()
  }, [api, gameID, accessToken, lastUpdate.current.time])

  const insertQueryRequestRecord = r => {
    const newRecords = [...queryRequestRecords, r]
    newRecords.sort((a, b) => {
      const ta = new Date(a.getReviewedAt()).getTime()
      const tb = new Date(b.getReviewedAt()).getTime()
      return tb - ta
    })
    setQueryRequestRecords(newRecords)
  }

  return {
    queryRequestRecords,
    queryRequestRecord: selectedQueryRequestRecord,
    insertQueryRequestRecord,
  }
}

export default useQueryRequestRecords
