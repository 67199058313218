export const queryBasicInfoHTML = query =>
  // eslint-disable-next-line implicit-arrow-linebreak
  `<b>${query.getName()}</b><br />ID: ${query.getID()}`

export const pendingQueryActionText = query => {
  if (query.isFilterUpdateRequestCreation()) {
    return 'creation'
  }
  if (query.isFilterUpdateRequestDeletion()) {
    return 'deletion'
  }
  return 'filter update'
}
