import React from 'react'

// components
import CreateEditPage from './CreateEditPage'

const EditPage = ({
  game, query, form, isFormEmpty, clearForm,
  onSubmit: handleSubmit, history,
}) => (
  <CreateEditPage
    pageTitle="Edit Query"
    game={game}
    query={query}
    form={form}
    isFormEmpty={isFormEmpty}
    clearForm={clearForm}
    onSubmit={handleSubmit}
    history={history}
  />
)

export default EditPage
