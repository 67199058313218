import Swal from 'sweetalert2'

const defaults = {
  icon: 'warning',
  showCancelButton: true,
  confirmButtonText: 'Confirm',
  cancelButtonText: 'Cancel',
  confirmButtonColor: '#38D42F',
}
const withDefaults = opts => ({ ...opts, ...defaults })

export const confirm = async (title, html) => {
  const result = await Swal.fire(withDefaults({ title, html }))
  return result.isConfirmed
}

export const prompt = async (title, html) => {
  const result = await Swal.fire(withDefaults({
    title,
    html,
    input: 'text',
    preConfirm: v => (!v ? false : v),
  }))
  return (result.isConfirmed && result.value) || undefined
}

export const alert = (title, html) => Swal.fire({
  title,
  html,
  icon: 'error',
  confirmButtonColor: 'gray',
})

export const warning = (title, html) => Swal.fire({
  title,
  html,
  icon: 'warning',
  confirmButtonColor: '#38D42F',
})
