import React from 'react'
import { useLocation } from 'react-router-dom'

// WL Design System
import {
  ListLayout,
  TableWrapper,
  PageHeader,
  IconButton,
} from 'wildlife-design-system'

import { PRODUCT_TITLE } from '../../../constants'
import LoadingPage from '../../LoadingPage'
import LocalMenu from '../LocalMenu'
import { ListRow } from '../../styles'
import { displayDate } from '../../../util'

const EventName = ({ value }) => (
  <span
    style={{
      border: '1px solid #B4B4B4',
      borderRadius: '3px',
      padding: '2px',
      whiteSpace: 'pre',
      fontFamily: 'monospace',
    }}
  >
    {value}
  </span>
)

const ListDiscardedLogsPage = ({
  game, gamesMenu, layoutConfig, logout, discardedLogs, user, history,
  discardedLog,
}) => {
  const { pathname, search: searchState } = useLocation()
  const search = () => new URLSearchParams(searchState)
  const discardedLogID = () => search().get('discarded_log_id') || ''
  const loading = discardedLogID() !== '' && discardedLogs.length === 0

  const handleClickRow = l => () => {
    const s = search()
    s.set('discarded_log_id', l.getID())
    history.push({
      pathname,
      search: s.toString(),
    })
  }

  if (discardedLogID() === '' && discardedLogs.length > 0) {
    handleClickRow(discardedLogs[0])()
  }

  return loading ? <LoadingPage /> : (
    <ListLayout
      productTitle={PRODUCT_TITLE}
      config={layoutConfig}
      games={gamesMenu}
      gameSlug={game.getIcon()}
      logout={logout}
    >
      <LocalMenu
        game={game}
        user={user}
        history={history}
      />
      <PageHeader
        title={`${game.getName()} Discarded Logs`}
      />
      <main>
        <TableWrapper>
          <table>
            <thead>
              <tr>
                <th>Origin</th>
                <th>{}</th>
                <th>Event Name</th>
                <th>Last discarded at</th>
              </tr>
            </thead>
            <tbody>
              {discardedLogs.map(l => (
                <ListRow
                  onClick={handleClickRow(l)}
                  selected={l.getID() === discardedLog.getID()}
                >
                  <td>{l.getOrigin()}</td>
                  <td
                    style={{
                      display: 'flex',
                      flexDirection: 'row-reverse',
                      marginRight: '5px',
                    }}
                  >
                    <IconButton
                      type="copy"
                      iconSize="10"
                      onClick={() => navigator.clipboard.writeText(l.getEventName())}
                    />
                  </td>
                  <td>
                    <EventName value={l.getEventName()} />
                  </td>
                  <td>{displayDate(l.getLastDiscarded())}</td>
                </ListRow>
              ))}
            </tbody>
          </table>
        </TableWrapper>
      </main>
    </ListLayout>
  )
}

export default ListDiscardedLogsPage
