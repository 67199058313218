/* eslint-disable no-param-reassign */
import React from 'react'
import styled from 'styled-components'

import {
  SectionTitle,
  Button,
} from 'wildlife-design-system'

import FilterEditor from './FilterEditor'
import FilterGenerator from './FilterGenerator'
import { SmallParagraph } from '../../styles'

const EditorMode = 'Editor Mode'
const GeneratorMode = 'Generator Mode'

const ModeSelectionButton = styled(Button)`
  margin-top: 15px;
`

const FilterSection = ({
  query,
  form,
  filterOptIn,
  optInForFilter,
  optOutForFilter,
  setOrigin,
  getOrigin,
  eventNames,
  removeEventName,
  setEventNames,
  eventName,
  setEventName,
  handleAddEventName,
  selectedDataFilter,
  setSelectedFieldPath,
  dataFilters,
  removeDataFilter,
  removeDataFilterByPath,
  fieldPath,
  allowedValueType,
  setFieldPath,
  setAllowedValueType,
  stringAllowedValue,
  setStringAllowedValue,
  numberAllowedValue,
  setNumberAllowedValue,
  booleanAllowedValue,
  setBooleanAllowedValue,
  handleAddDataFilter,
  setDataFilters,
}) => {
  const [
    mode, setModeState,
  ] = React.useState(form.mode || (query.getID() && EditorMode) || GeneratorMode)
  const setMode = m => {
    form.mode = m
    setModeState(m)
  }
  const setEditorMode = () => setMode(EditorMode)
  const setGeneratorMode = () => setMode(GeneratorMode)

  return (
    <>
      <SectionTitle>
        {`${query.getID() ? 'Edit ' : ''}Query Filters${filterOptIn ? `: ${mode}` : ''}`}
      </SectionTitle>
      {!filterOptIn && (
        <>
          <SmallParagraph>
            You can request an update on the filters of this query.
            The changes will only take place when someone with the
            required permissions approve your request.
          </SmallParagraph>
          <Button type="outlined" onClick={optInForFilter}>Edit query filters</Button>
        </>
      )}
      {filterOptIn && (
        <>
          {mode === EditorMode && (
            <>
              <ModeSelectionButton
                type="outlined"
                onClick={setGeneratorMode}
              >
                Generate filters from log samples
              </ModeSelectionButton>

              <FilterEditor
                setOrigin={setOrigin}
                getOrigin={getOrigin}
                eventNames={eventNames}
                removeEventName={removeEventName}
                eventName={eventName}
                setEventName={setEventName}
                handleAddEventName={handleAddEventName}
                selectedDataFilter={selectedDataFilter}
                setSelectedFieldPath={setSelectedFieldPath}
                dataFilters={dataFilters}
                removeDataFilter={removeDataFilter}
                removeDataFilterByPath={removeDataFilterByPath}
                fieldPath={fieldPath}
                allowedValueType={allowedValueType}
                setFieldPath={setFieldPath}
                setAllowedValueType={setAllowedValueType}
                stringAllowedValue={stringAllowedValue}
                setStringAllowedValue={setStringAllowedValue}
                numberAllowedValue={numberAllowedValue}
                setNumberAllowedValue={setNumberAllowedValue}
                booleanAllowedValue={booleanAllowedValue}
                setBooleanAllowedValue={setBooleanAllowedValue}
                handleAddDataFilter={handleAddDataFilter}
              />
            </>
          )}

          {mode === GeneratorMode && (
            <>
              <ModeSelectionButton
                type="outlined"
                onClick={setEditorMode}
              >
                Go to editor mode
              </ModeSelectionButton>

              <FilterGenerator
                form={form}
                onGenerate={setEditorMode}
                setOrigin={setOrigin}
                setEventNames={setEventNames}
                setDataFilters={setDataFilters}
              />
            </>
          )}

          {optOutForFilter && (
            <Button
              profile="error"
              type="outlined"
              onClick={optOutForFilter}
              style={{ 'margin-top': '1.5rem' }}
            >
              Cancel filter update
            </Button>
          )}
        </>
      )}
    </>
  )
}

export default FilterSection
