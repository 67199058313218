/* eslint-disable no-throw-literal */
/* eslint-disable no-param-reassign */
import React from 'react'
import styled from 'styled-components'

import {
  Button,
  Label,
  ExternalLink,
  InputCode,
  IconButton,
} from 'wildlife-design-system'

import {
  QueryFilter,
  Log,
} from '../../../objects/queries'
import { SmallParagraph } from '../../styles'
import { alert, warning } from '../../../util'

const ExplanationParagraph = styled(SmallParagraph)`
  margin-bottom: 0;
`

const ParagraphAnchor = styled(ExternalLink)`
  font-size: 14px;
  font-family: "Roboto",Helvetica,Arial,sans-serif;
  padding-left: 3px;
`

const LogRow = styled.div`
  display: grid;
  grid-template-columns: auto 20%;
`

const LogDeleteWrapper = styled.div`
  margin-left: 15px;
`

const ButtonsContainer = styled.div`
  margin-top: 15px;
`

const sampleLabel = i => `Log Sample #${i + 1}`

const originSuffix = s => {
  try {
    return new Log(JSON.parse(s)).isOriginTopaz() ? ' (Origin: Game Clients)' : ' (Origin: Game Servers)'
  } catch (e) {
    return ''
  }
}

const FilterGenerator = ({
  form,
  onGenerate: handleGenerate,
  setOrigin,
  setEventNames,
  setDataFilters,
}) => {
  const [samples, setSamplesState] = React.useState(form.samples || ['', ''])
  const setSamples = s => { form.samples = s; setSamplesState(s) }
  const setSample = (v, i) => {
    samples[i] = v
    setSamples([...samples])
  }
  const handleClickRemoveSample = i => {
    if (samples.length > 2) {
      setSamples([
        ...samples.slice(0, i),
        ...samples.slice(i + 1),
      ])
    }
  }
  const handleClickAddSampleInput = () => setSamples([...samples, ''])

  const handleClickGenerate = () => {
    let logs
    try {
      logs = samples.map((s, i) => {
        const prefix = sampleLabel(i)

        let o
        try {
          o = JSON.parse(s)
        } catch (e) {
          throw `${prefix} is not a valid JSON.`
        }

        if (typeof o !== 'object' || o === null || Array.isArray(o)) {
          throw `${prefix} is not an object.`
        }

        if (Object.keys(o).length === 0) {
          throw `${prefix} does not contain any keys.`
        }

        return new Log(o)
      })
    } catch (e) {
      alert('Log Parsing Error', e)
      return
    }

    const countTopaz = logs.filter(l => l.isOriginTopaz()).length
    if (countTopaz > 0 && countTopaz < logs.length) {
      alert(
        'Impossible Filter',
        'It is not possible to build a query matching logs from both client and servers.',
      )
      return
    }

    const origin = logs[0].isOriginTopaz() ? 'client' : 'server'

    const eventName = logs[0].getEventName()
    const countEventName = logs
      .filter(l => (l.getEventName() || undefined) === eventName)
      .length
    const eventNames = countEventName === logs.length ? [eventName] : []

    const allPaths = Object.keys(
      logs
        .reduce((acc, l) => [...acc, ...l.getPathList()], [])
        .reduce((acc, p) => ({ ...acc, [p]: true }), {}),
    )
    const dataFilters = allPaths.reduce((acc1, p) => {
      const values = logs[0]
        .getValuesAtPath(p)
        .filter(v => logs
          .reduce((acc2, l) => acc2 && l.getValuesAtPath(p).includes(v), true))
      return values.length > 0 ? { ...acc1, [p]: values } : acc1
    }, {})
    const f = new QueryFilter()
    Object.keys(dataFilters)
      .forEach(p => f.addDataFilter(p, dataFilters[p]))

    if (eventNames.length === 0 && !f.hasDataFilters()) {
      alert(
        'Log Samples Error',
        'These logs do not contain any data in common.',
      )
      return
    }

    if ((eventNames.length > 0 ? 1 : 0) + f.countDataFilters() > 3) {
      warning(
        'Warning',
        'These logs have too many data fields in common, which results in a filter suggestion that is very restrictive. It is probably a good idea to remove some of the suggested filters.',
      )
    }

    setOrigin(origin)
    setEventNames(eventNames)
    setDataFilters(f)

    handleGenerate()
  }

  return (
    <>
      <Label>Generate filters from log samples</Label>

      <ExplanationParagraph>
        This mode facilitates the creation of your query by
        allowing you to generate the filters based on a set of
        log samples in the JSON format. You may use the
        editor mode afterwards to refine these filters.
        <br />
        <br />
        The algorithm suggests as filters the fields that are
        simultaneously present in all the log samples and are
        mapping to the same values.
        <br />
        <br />
        To use this tool you may reach the developers team to ask
        for a few samples that precisely showcase the query that
        you want to build. These logs should be real-life JSON
        examples, following the format defined
        <ParagraphAnchor
          href="https://git.topfreegames.com/player-support/angel-queries/-/blob/master/grpc/proto/core/log.proto"
        >
          here
        </ParagraphAnchor>
        .
      </ExplanationParagraph>

      {samples.length > 0 && (
        samples.map((s, i) => (
          <>
            <Label htmlFor={`log-${i}`}>
              {`${sampleLabel(i)}${originSuffix(s)}`}
            </Label>
            <LogRow>
              <InputCode
                name={`log-${i}`}
                mode="json"
                height="200px"
                width="100%"
                value={s}
                onChange={v => setSample(v, i)}
                placeholder="Paste here a log sample"
              />
              <LogDeleteWrapper>
                <IconButton
                  type="delete"
                  iconSize="14"
                  onClick={e => { e.preventDefault(); handleClickRemoveSample(i) }}
                />
              </LogDeleteWrapper>
            </LogRow>
          </>
        ))
      )}

      <ButtonsContainer>
        <Button
          type="outlined"
          onClick={handleClickAddSampleInput}
        >
          {`Add ${samples.length > 0 ? 'another' : 'log'} sample`}
        </Button>
      </ButtonsContainer>

      <ButtonsContainer>
        <Button
          type="outlined"
          onClick={handleClickGenerate}
        >
          Generate filters
        </Button>
      </ButtonsContainer>
    </>
  )
}

export default FilterGenerator
