import React from 'react'
import styled from 'styled-components'
import { Label } from 'wildlife-design-system'
import { FlexibleColumnPair } from '../styles'
import { displayDate, nameFromEmailOrEmail } from '../../util'

const StyledLabel = styled(Label)`
  padding-top: 15px;
  padding-bottom: 0;
  color: #B4B4B4;
  font-family: "Roboto Condensed",Helvetica,Arial,sans-serif;
  font-size: 14px;
  line-height: 17px;
`

const UserLabel = styled(Label)`
  color: #595959;
  font-family: "Roboto",Helvetica,Arial,sans-serif;
  font-size: 14px;
  line-height: 19px;
  padding: 0;
`

const DateLabel = styled.time`
  color: #595959;
  font-family: "Roboto",Helvetica,Arial,sans-serif;
  font-size: 14px;
  line-height: 19px;
`

const User = ({ email }) => {
  const name = nameFromEmailOrEmail(email)
  const info = name !== email ? email : undefined
  return <UserLabel info={info}>{name}</UserLabel>
}

const UsernamesAndDates = ({ query }) => (
  <FlexibleColumnPair>
    <div>
      <StyledLabel>Created by</StyledLabel>
      <User email={query.getCreatedBy()} />
      <StyledLabel>Updated by</StyledLabel>
      <User email={query.getUpdatedBy()} />
    </div>
    <div>
      <StyledLabel>at</StyledLabel>
      <DateLabel>{displayDate(query.getCreatedAt())}</DateLabel>
      <StyledLabel>at</StyledLabel>
      <DateLabel>{displayDate(query.getUpdatedAt())}</DateLabel>
    </div>
  </FlexibleColumnPair>
)

export default UsernamesAndDates
