import styled from 'styled-components'

const ListBoxContainerParagraph = styled.p`
  color: #7A7A7A;
  font-family: "Roboto",Helvetica,Arial,sans-serif;
  font-size: 16px;
  line-height: 21px;
  width: 430px;
`

export default ListBoxContainerParagraph
