import React from 'react'
import PropTypes from 'prop-types'
import Auth from '../../api/Auth'

const Login = ({ history }) => {
  if (new URL(window.location).searchParams.get('force') === 'true') {
    Auth.logout()
  }

  Auth.authenticate(history)
  return <div />
}

Login.propTypes = {
  history: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.number,
    PropTypes.object,
  ])).isRequired,
}

export default Login
