class DiscardedLog {
  constructor(data) {
    const objectData = data || {}
    Object.assign(this, objectData, { objectData })
  }

  getID() {
    return `${this.getOrigin()}$${this.getEventName()}`
  }

  getOrigin() {
    return this.origin || ''
  }

  getEventName() {
    return this.event_name || ''
  }

  getLastDiscarded() {
    return this.last_discarded || ''
  }
}

export default DiscardedLog
