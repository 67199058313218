import React from 'react'

// WL Design System
import {
  AppCard,
  GalleryLayout,
} from 'wildlife-design-system'

import {
  PRODUCT_TITLE,
  CREATE_QUERY,
  LIST_QUERIES,
  LIST_QUERY_REQUEST_RECORDS,
  LIST_DISCARDED_LOGS,
} from '../../constants'
import {
  createQueryPath,
  listQueriesPath,
  listQueryRequestRecordsPath,
  listDiscardedLogsPath,
} from '../../paths'

const getItemsForGameAndUser = (game, user) => {
  const items = []

  if (user.hasCreateQuery(game)) {
    items.push({ label: CREATE_QUERY, path: createQueryPath(game) })
  }
  if (user.hasListQueries(game)) {
    items.push({ label: LIST_QUERIES, path: listQueriesPath(game) })
  }
  if (user.hasListDiscardedLogs(game)) {
    items.push({ label: LIST_DISCARDED_LOGS, path: listDiscardedLogsPath(game) })
  }
  if (user.hasListQueryRequestRecords(game)) {
    items.push({ label: LIST_QUERY_REQUEST_RECORDS, path: listQueryRequestRecordsPath(game) })
  }

  return items
}

const HomePage = ({
  game, games, gamesMenu, layoutConfig, user, logout,
}) => (
  <GalleryLayout
    productTitle={PRODUCT_TITLE}
    config={layoutConfig}
    games={gamesMenu}
    gameSlug={(game && game.getIcon()) || undefined}
    logout={logout}
  >
    {games
      .map(g => ({
        game: g,
        items: getItemsForGameAndUser(g, user),
      }))
      .filter(app => app.items.length > 0)
      .map(app => (
        <AppCard
          appId={app.game.getIcon()}
          appName={app.game.getName()}
          items={app.items}
        />
      ))}
  </GalleryLayout>
)

export default HomePage
