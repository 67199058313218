// imports
import QueryFilter from './QueryFilter'
import QueryFilterUpdateRequest from './QueryFilterUpdateRequest'
import { patch } from '../helpers'
import { now } from '../../util'

// private helpers
// eslint-disable-next-line arrow-body-style
const patchFilterUpdateRequest = (self, req) => {
  patch(self, { filter_update_request: JSON.parse(req.stringify()) })
}
const removeFilterUpdateRequest = self => {
  patchFilterUpdateRequest(self, new QueryFilterUpdateRequest())
}

class Query {
  constructor(data) {
    const objectData = data || {}
    Object.assign(this, objectData, { objectData })
  }

  stringify() {
    return JSON.stringify(this.objectData)
  }

  cloneForSynchronization() {
    const c = new Query(JSON.parse(this.stringify()))
    c.isClone = true
    return c
  }

  getID() {
    return this.id || ''
  }

  setID(id) {
    patch(this, { id })
  }

  getName() {
    return this.name || ''
  }

  setName(name) {
    patch(this, { name })
  }

  getDescription() {
    return this.description || ''
  }

  setDescription(description) {
    patch(this, { description })
  }

  getFilter() {
    return new QueryFilter(this.filter)
  }

  setFilter(filter) {
    patch(this, { filter: JSON.parse(filter.stringify()) })
  }

  getAllowedEventNames() {
    return this.getFilter().getAllowedEventNames()
  }

  isOriginTopaz() {
    return this.getFilter().isOriginTopaz()
  }

  getCreatedAt() {
    return this.created_at || now()
  }

  getUpdatedAt() {
    return this.updated_at || now()
  }

  getCreatedBy() {
    return this.created_by || ''
  }

  getUpdatedBy() {
    return this.updated_by || ''
  }

  getFilterUpdateRequest() {
    return new QueryFilterUpdateRequest(this.filter_update_request)
  }

  getProposedFilter() {
    return this.getFilterUpdateRequest().getFilter()
  }

  getFilterData() {
    return this.getFilter().getData()
  }

  getProposedFilterData() {
    return this.getProposedFilter().getData()
  }

  hasFilterDifference(otherFilter) {
    return this.getFilter().getDifference(otherFilter) !== ''
  }

  isFilterEmpty() {
    return this.getFilter().isEmpty()
  }

  hasFilterUpdateRequest() {
    return this.getFilterUpdateRequest().exists()
  }

  isFilterUpdateRequestCreation() {
    return this.hasFilterUpdateRequest() && this.isFilterEmpty()
  }

  isFilterUpdateRequestDeletion() {
    return this.hasFilterUpdateRequest() && this.getFilterUpdateRequest().isFilterEmpty()
  }

  isSynchronizing() {
    return this.isClone
  }

  requestCreation(username, motivation) {
    patch(this, {
      created_at: '0001-01-01T00:00:00Z',
      updated_at: '0001-01-01T00:00:00Z',
    })

    const filter = this.getFilter()
    this.setFilter(new QueryFilter())
    const req = new QueryFilterUpdateRequest()
    req.request(username, motivation, filter)
    patchFilterUpdateRequest(this, req)
  }

  requestUpdate(formQuery, username, motivation) {
    const name = formQuery.getName()
    const description = formQuery.getDescription()
    if (name !== this.getName() || description !== this.getDescription()) {
      patch(this, {
        name,
        description,
        updated_at: now(),
        updated_by: username,
      })
    }

    const filter = formQuery.getFilter()
    if (!filter.isEmpty()) {
      const req = new QueryFilterUpdateRequest()
      req.request(username, motivation, filter)
      patchFilterUpdateRequest(this, req)
    }
  }

  requestDeletion(username, motivation) {
    const req = new QueryFilterUpdateRequest()
    req.request(username, motivation, new QueryFilter())
    patchFilterUpdateRequest(this, req)
  }

  acceptRequest() {
    patch(this, { filter: this.getProposedFilterData() })
    removeFilterUpdateRequest(this)
  }

  rejectRequest() {
    removeFilterUpdateRequest(this)
  }
}

export default Query
