import React from 'react'
import Proptypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'
import Auth from '../../api/Auth'

const PrivateRoute = ({ component: Component, path, ...rest }) => (
  <Route
    {...rest}
    path={path}
    render={props => (Auth.isAuthenticated() ? (
      <Component {...props} />
    ) : (
      <Redirect to={{ pathname: '/login', state: { from: props.location }, search: `?referer=${encodeURIComponent(path)}` }} />
    ))}
  />
)

PrivateRoute.propTypes = {
  component: Proptypes.element.isRequired,
  path: Proptypes.string.isRequired,
  location: Proptypes.string.isRequired,
}

export default PrivateRoute
