import React from 'react'
import { Route, Switch } from 'react-router-dom'
import './App.css'

// pages
import PrivateRoute from './components/PrivateRoute'
import Login from './components/Login'
import HomePage from './components/HomePage/HomePage'
import LoadingPage from './components/LoadingPage'
import NotFoundPage from './components/NotFoundPage/NotFoundPage'
import {
  CreateQueryPage,
  EditQueryPage,
  ListQueriesPage,
  ListQueryRequestRecordsPage,
  ListDiscardedLogsPage,
} from './components/queries'

// hooks
import {
  useDiscardedLogs,
  useGames,
  useQueries,
  useQueryForm,
  useQueryRequestRecords,
  useUser,
} from './hooks'

// api
import auth from './api/Auth'
import api from './api/api'
import iam from './api/iam'

// paths
import {
  createQueryPath,
  listQueriesPath,
  listQueryRequestRecordsPath,
  listDiscardedLogsPath,
} from './paths'

// constants
import {
  CREATE_QUERY,
  LIST_QUERIES,
  LIST_QUERY_REQUEST_RECORDS,
  LIST_DISCARDED_LOGS,
} from './constants'

// local imports
// import { nameFromEmailOrEmail } from './util' TODO: use in user layout config when it has support

const gameMenu = (game, user) => {
  if (!game || !user) {
    return []
  }

  const menu = []

  const queries = []
  if (user.hasCreateQuery(game)) {
    queries.push({ title: CREATE_QUERY, path: createQueryPath(game) })
  }
  if (user.hasListQueries(game)) {
    queries.push({ title: LIST_QUERIES, path: listQueriesPath(game) })
  }
  if (user.hasListDiscardedLogs(game)) {
    queries.push({ title: LIST_DISCARDED_LOGS, path: listDiscardedLogsPath(game) })
  }
  if (user.hasListQueryRequestRecords(game)) {
    queries.push({ title: LIST_QUERY_REQUEST_RECORDS, path: listQueryRequestRecordsPath(game) })
  }
  if (queries.length > 0) {
    menu.push({ title: 'Manage Queries', pages: queries })
  }

  return menu
}

const WithObjects = ({ user, objects, children }) => {
  if (!user || objects.filter(o => !o.getID()).length > 0) {
    return <LoadingPage />
  }

  return children
}

const App = () => {
  // hooks
  const {
    user, unauthenticated,
  } = useUser(iam, api, auth.getEmail(), auth.getToken())
  if (unauthenticated) {
    auth.logout()
  }

  const { games, game } = useGames(api, auth.getToken())

  const {
    queryRequestRecords,
    queryRequestRecord,
    insertQueryRequestRecord,
  } = useQueryRequestRecords(api, game.getID(), auth.getToken())

  const {
    queries,
    query,
    createQuery: handleCreateQuery,
    editQuery: handleEditQuery,
    deleteQuery: handleDeleteQuery,
    acceptQueryRequest: handleAcceptQueryRequest,
    rejectQueryRequest: handleRejectQueryRequest,
  } = useQueries(api, game.getID(), auth.getToken(), auth.getEmail(), insertQueryRequestRecord)

  const {
    discardedLogs,
    discardedLog,
  } = useDiscardedLogs(api, game.getID(), auth.getToken())

  const {
    queryForm,
    isQueryFormEmpty,
    clearQueryForm,
  } = useQueryForm(game.getID(), query.getID())

  // global navigation setup
  const gamesMenu = games
    .filter(g => gameMenu(g, user).length > 0)
    .map(g => ({
      label: g.getName(),
      to: `/games?game_id=${g.getID()}`,
      appIcon: g.getIcon(),
    }))
  const layoutConfig = {
    user: {
      email: user.getEmail(),
      picture: user.getPicture(),
    },
    global: gameMenu(game, user),
  }
  const handleLogout = history => () => {
    auth.logout()
    history.push('/')
  }

  return (
    <Switch>
      <Route
        exact
        path="/login"
        component={({ history }) => <Login history={history} />}
      />
      <PrivateRoute
        exact
        path="/games"
        component={({ history }) => (
          <WithObjects user={user} objects={[game]}>
            <HomePage
              game={game}
              games={[game]}
              gamesMenu={gamesMenu}
              layoutConfig={layoutConfig}
              user={user}
              logout={handleLogout(history)}
            />
          </WithObjects>
        )}
      />
      <PrivateRoute
        exact
        path="/games/queries"
        component={({ history }) => (
          <WithObjects user={user} objects={[game]}>
            <ListQueriesPage
              game={game}
              gamesMenu={gamesMenu}
              layoutConfig={layoutConfig}
              logout={handleLogout(history)}
              queries={queries}
              user={user}
              history={history}
              onAcceptQueryRequest={handleAcceptQueryRequest}
              onRejectQueryRequest={handleRejectQueryRequest}
              onDeleteQuery={handleDeleteQuery}
              query={query}
            />
          </WithObjects>
        )}
      />
      <PrivateRoute
        exact
        path="/games/queries/create"
        component={({ history }) => (
          <WithObjects user={user} objects={[game]}>
            <CreateQueryPage
              game={game}
              form={queryForm}
              isFormEmpty={isQueryFormEmpty}
              clearForm={clearQueryForm}
              onSubmit={handleCreateQuery}
              history={history}
            />
          </WithObjects>
        )}
      />
      <PrivateRoute
        exact
        path="/games/queries/edit"
        component={({ history }) => (
          <WithObjects user={user} objects={[game, query]}>
            <EditQueryPage
              game={game}
              query={query}
              form={queryForm}
              isFormEmpty={isQueryFormEmpty}
              clearForm={clearQueryForm}
              onSubmit={handleEditQuery}
              history={history}
            />
          </WithObjects>
        )}
      />
      <PrivateRoute
        exact
        path="/games/query_request_records"
        component={({ history }) => (
          <WithObjects user={user} objects={[game]}>
            <ListQueryRequestRecordsPage
              game={game}
              gamesMenu={gamesMenu}
              layoutConfig={layoutConfig}
              logout={handleLogout(history)}
              queryRequestRecords={queryRequestRecords}
              user={user}
              history={history}
              queryRequestRecord={queryRequestRecord}
              queries={queries}
            />
          </WithObjects>
        )}
      />
      <PrivateRoute
        exact
        path="/games/discarded_logs"
        component={({ history }) => (
          <WithObjects user={user} objects={[game]}>
            <ListDiscardedLogsPage
              game={game}
              gamesMenu={gamesMenu}
              layoutConfig={layoutConfig}
              logout={handleLogout(history)}
              discardedLogs={discardedLogs}
              user={user}
              history={history}
              discardedLog={discardedLog}
            />
          </WithObjects>
        )}
      />
      <PrivateRoute
        exact
        path="/"
        component={({ history }) => (
          <HomePage
            games={games}
            gamesMenu={gamesMenu}
            layoutConfig={layoutConfig}
            user={user}
            logout={handleLogout(history)}
          />
        )}
      />
      <Route
        exact
        from="*"
        component={() => <NotFoundPage />}
      />
    </Switch>
  )
}

export default App
