import styled from 'styled-components'

const ListMain = styled.main(
  ({ withBox }) => `
    ${(withBox && `
      display: grid;
      grid-template-columns: auto 38px 588px;
    `) || ''}
  `,
)

export default ListMain
