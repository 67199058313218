import React from 'react'

const useQueryForm = (gameID, queryID) => {
  const [form, setForm] = React.useState({})
  form[gameID] = form[gameID] || {}
  form[gameID][queryID] = form[gameID][queryID] || {}

  return {
    queryForm: form[gameID][queryID],
    isQueryFormEmpty: () => Object.keys(form[gameID][queryID]).length === 0,
    clearQueryForm: () => setForm({
      ...form,
      [gameID]: {
        ...form[gameID],
        [queryID]: {},
      },
    }),
  }
}

export default useQueryForm
