import React from 'react'
import { useLocation } from 'react-router-dom'

// WL Design System
import {
  ListLayout,
  TableWrapper,
  Badge,
  PageHeader,
} from 'wildlife-design-system'

import { PRODUCT_TITLE } from '../../../constants'
import LoadingPage from '../../LoadingPage'
import { pendingQueryActionText } from './util'
import QueryHeader from './QueryHeader'
import QueryFilter from './QueryFilter'
import FooterActions from './FooterActions'
import LocalMenu from '../LocalMenu'
import { ListRow, ListMain, ListBoxContainer } from '../../styles'

const ListPage = ({
  game, gamesMenu, layoutConfig, logout, queries, user, history,
  onAcceptQueryRequest: handleAcceptQueryRequest,
  onRejectQueryRequest: handleRejectQueryRequest,
  onDeleteQuery: handleDeleteQuery,
  query,
}) => {
  const { pathname, search: searchState } = useLocation()
  const search = () => new URLSearchParams(searchState)
  const queryID = () => search().get('query_id') || ''
  const loading = queryID() !== '' && queries.length === 0

  const boxRef = React.useRef(null)

  const handleClickQuery = q => () => {
    const s = search()
    s.set('query_id', q.getID())
    history.push({
      pathname,
      search: s.toString(),
    })
    setTimeout(
      boxRef
      && boxRef.current
      && boxRef.current.scrollIntoView({ behavior: 'smooth' }),
      250,
    )
  }

  if (queryID() === '' && queries.length > 0) {
    handleClickQuery(queries[0])()
  }

  return loading ? <LoadingPage /> : (
    <ListLayout
      productTitle={PRODUCT_TITLE}
      config={layoutConfig}
      games={gamesMenu}
      gameSlug={game.getIcon()}
      logout={logout}
    >
      <LocalMenu
        game={game}
        user={user}
        history={history}
      />
      <PageHeader
        title={`${game.getName()} Queries`}
      />
      <ListMain withBox={!!query.getID()}>
        <TableWrapper>
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {queries.map(q => (
                <ListRow
                  onClick={handleClickQuery(q)}
                  selected={q.getID() === query.getID()}
                >
                  <td>{q.getName()}</td>
                  <td>
                    {q.hasFilterUpdateRequest() && (
                      <Badge profile="warning">
                        {`Pending ${pendingQueryActionText(q)} approval`}
                      </Badge>
                    )}
                  </td>
                </ListRow>
              ))}
            </tbody>
          </table>
        </TableWrapper>
        <div />
        {query.getID() && (
          <div ref={boxRef}>
            <ListBoxContainer profile="sidebar">
              <QueryHeader
                game={game}
                user={user}
                query={query}
                history={history} // to handle edit query
                onDeleteQuery={handleDeleteQuery}
              />
              <QueryFilter query={query} />
              <FooterActions
                game={game}
                user={user}
                query={query}
                onAcceptQueryRequest={handleAcceptQueryRequest}
                onRejectQueryRequest={handleRejectQueryRequest}
              />
            </ListBoxContainer>
          </div>
        )}
      </ListMain>
    </ListLayout>
  )
}

export default ListPage
