const apiHost = process.env.REACT_APP_API_HOST

// helpers
const fetchResource = async (path, accessToken) => {
  const options = {
    headers: { Authorization: `Bearer ${accessToken}` },
  }
  const opts = (accessToken && options) || undefined
  const resp = await fetch(`${apiHost}/api${path}`, opts)
  if (resp.status >= 400) {
    throw resp
  }
  return resp.json()
}
const postResource = async (path, body, accessToken) => {
  const options = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  }
  const resp = await fetch(`${apiHost}/api${path}`, options)
  if (resp.status >= 400) {
    throw resp
  }
  return resp.json()
}

export default {
  listGames: accessToken => fetchResource('/games', accessToken),

  listUserPermissions: accessToken => fetchResource('/user_permissions', accessToken),

  requestQueryCreation: (gameID, query, motivation, accessToken) => postResource(
    `/games/${gameID}/queries`,
    { query, motivation },
    accessToken,
  ),
  requestQueryUpdate: (gameID, queryID, query, motivation, accessToken) => postResource(
    `/games/${gameID}/queries/${queryID}/:requestUpdate`,
    { query, motivation },
    accessToken,
  ),
  requestQueryDeletion: (gameID, queryID, motivation, accessToken) => postResource(
    `/games/${gameID}/queries/${queryID}/:requestDeletion`,
    { motivation },
    accessToken,
  ),
  acceptQueryRequest: (gameID, queryID, accessToken) => postResource(
    `/games/${gameID}/queries/${queryID}/:acceptRequest`,
    {},
    accessToken,
  ),
  rejectQueryRequest: (gameID, queryID, reason, accessToken) => postResource(
    `/games/${gameID}/queries/${queryID}/:rejectRequest`,
    { reason },
    accessToken,
  ),
  listQueries: (gameID, accessToken) => fetchResource(`/games/${gameID}/queries`, accessToken),
  listQueryRequestRecords: (gameID, accessToken) => fetchResource(
    `/games/${gameID}/query_request_records`,
    accessToken,
  ),
  listDiscardedLogs: (gameID, accessToken) => fetchResource(
    `/games/${gameID}/discarded_logs`,
    accessToken,
  ),
}
